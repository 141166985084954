import React, { FC } from "react";
import styled from "styled-components";

interface BreakPointProps {
    screenSize?: string;
    display?: string;
}

/**
 * <ShowWhenLessThan screenSize="lg">
 *      Hidden by default
 *      BUT if the screen size is less than size, shows again
 *      Size is defined by theme.breakpoint - sm, md, lg, xl, xxl, xxxl
 * </ShowWhenLessThan>
 */
export const ShowWhenLessThan = styled.div<BreakPointProps>`
    display: ${(p) => p.display || "block"};
    @media (min-width: ${(p) =>
            p.theme.breakpoint[p.screenSize] || p.theme.breakpoint.lg}px) {
        display: none;
    }
`;

/**
 * <ShowWhenGreaterThan screenSize="lg">
 *      Visible by default
 *      BUT if the screen size is less than size, hide
 *      Size is defined by theme.breakpoint - sm, md, lg, xl, xxl, xxxl
 * </ShowWhenGreaterThan>
 */
export const ShowWhenGreaterThan = styled.div<BreakPointProps>`
    display: none;

    @media (min-width: ${(p) =>
            p.theme.breakpoint[p.screenSize] || p.theme.breakpoint.lg}px) {
        display: ${(p) => p.display || "block"};
    }
`;
