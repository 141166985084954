import { graphql } from "gatsby";
import React from "react";
import { ArticleListLayout } from "../components/Articles/ArticleListLayout";
import { ArticleTiles } from "../components/Articles/ArticleTiles";
import { BlogSubscriptionForm } from "../components/Blog/BlogSubscriptionForm";
import { PageSectionContainer } from "../components/PageSection";
import { Pagination } from "../components/Pagination";
import { Head } from "../components/Head";
import { SEOOnly } from "../components/SEOOnly";
import toArticle from "../util/toArticle";
import styled from "styled-components";
import { BlogListFeaturedArticles } from "../components/BlogList/BlogListFeaturedArticles";
import { BlogHeader } from "../components/BlogList/BlogHeader";
import { BLOG_TITLE } from "../constants/titles";
import { ContentGroups } from "../constants/contentGroups";

const PageSection = styled(PageSectionContainer)`
    background-color: ${(p) => p.theme.color.background.default};
    padding: 24px 20px;

    ${(p) => p.theme.media("md")} {
        padding: 60px 56px;
    }

    ${(p) => p.theme.media("xl")} {
        padding: 60px 80px;
    }
`;

const BlogListTemplate = (props: {
    data: GatsbyTypes.blogListQueryQuery;
    pageContext: { numPages: number; currentPage: number };
}) => {
    const { numPages, currentPage } = props.pageContext;

    const articles = props.data.allSanityBlogPost.nodes.map(toArticle);

    return (
        <>
            <Head
                contentGroup={ContentGroups["limited-aware"]}
                title={BLOG_TITLE}
            />
            <SEOOnly>
                <h1>Blog</h1>
            </SEOOnly>
            <PageSection>
                <BlogHeader />
                <BlogListFeaturedArticles
                    articles={props.data.featuredArticles.nodes[0].blogPosts}
                />
                <ArticleListLayout>
                    <ArticleTiles articles={articles} />
                    <Pagination numPages={numPages} currentPage={currentPage} />
                </ArticleListLayout>
            </PageSection>
            <BlogSubscriptionForm />
        </>
    );
};

export default BlogListTemplate;

export const blogListQuery = graphql`
    query blogListQuery($skip: Int!, $limit: Int!) {
        allSanityBlogPost(
            skip: $skip
            limit: $limit
            sort: { fields: publishedAt, order: DESC }
            filter: { tags: { elemMatch: { title: { ne: "in-app-only" } } } }
        ) {
            nodes {
                author {
                    ... on SanityCollaborator {
                        image {
                            _key
                        }
                    }
                    ... on SanityAuthor {
                        image {
                            _key
                        }
                    }
                }
                slug {
                    current
                }
                canonicalUrl
                featuredImage {
                    img {
                        asset {
                            gatsbyImageData(layout: CONSTRAINED, height: 480)
                        }
                    }
                    alt
                }
                description
                title
                publishedAt(formatString: "MMMM YYYY")
                category {
                    name
                }
            }
        }

        featuredArticles: allSanityBlogListSettings {
            nodes {
                blogPosts {
                    author {
                        ... on SanityCollaborator {
                            image {
                                _key
                            }
                        }
                        ... on SanityAuthor {
                            image {
                                _key
                            }
                        }
                    }
                    slug {
                        current
                    }
                    canonicalUrl
                    featuredImage {
                        alt
                        img {
                            asset {
                                gatsbyImageData(
                                    layout: CONSTRAINED
                                    height: 480
                                )
                            }
                        }
                    }
                    description
                    title
                    publishedAt(formatString: "MMMM YYYY")
                    category {
                        name
                    }
                }
            }
        }
    }
`;
