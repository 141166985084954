import React from "react";
import styled from "styled-components";
import { Heading } from "../Typography/Heading";
import { ShareLinks } from "../ShareLinks";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

const FlippedText = styled.span`
    display: inline-block;
    transform: rotate(180deg);
    transform-origin: 0.28em 0.58em;

    :after {
        content: "a";
    }
`;

const EasyAgileLogoText = () => {
    return (
        <>
            <FlippedText aria-hidden={true}></FlippedText>
            <ScreenReaderOnly>e</ScreenReaderOnly>
        </>
    );
};

const Stories = () => {
    return (
        <>
            Stori
            <EasyAgileLogoText />s
        </>
    );
};

const BlogHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 60px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    ${(p) => p.theme.media("md")} {
        gap: 24px;
    }

    ${(p) => p.theme.media("xl")} {
        flex-direction: row;
        gap: unset;
    }
`;

const BlogHeading = styled(Heading)`
    font-family: Avenir Black Oblique, Helvetica, sans-serif;
    display: inline-block;
    margin: 0;
    padding-right: 0.25em;
    line-height: 1em;

    & {
        margin-bottom: 0px;
        color: ${(p) => p.theme.color.primary.main};
        flex-grow: 1;
    }
`;

export const BlogHeader = () => {
    return (
        <BlogHeaderWrapper>
            <div>
                <BlogHeading level="h1">
                    <Stories />
                </BlogHeading>
                <BlogHeading forwardedAs="p" level="h4">
                    by Easy Agile
                </BlogHeading>
            </div>
            <ShareLinks />
        </BlogHeaderWrapper>
    );
};
