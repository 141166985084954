import { useEffect, useState } from "react";

const useMedia = (minWidth: number) => {
    // couldn't use query for initial state as netfliy requires window object to be stored in useEffect
    const [isMinWidth, setIsMinWidth] = useState();

    useEffect(() => {
        const mql = window.matchMedia(`(min-width: ${minWidth}px)`);
        const query = () => mql.matches;

        setIsMinWidth(query());

        const onWidthChange = () => {
            setIsMinWidth(query());
        };

        mql.addListener(onWidthChange);
        return () => mql.removeListener(onWidthChange);
    }, []);

    return isMinWidth;
};

export default useMedia;
