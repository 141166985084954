import React, {
    useMemo,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";
import styled, { css, keyframes } from "styled-components";
import { gridSquares } from "../styles/grid";
import { Arrow } from "./icons/Arrow";
import { theme } from "../styles/theme";
import { ShowWhenGreaterThan } from "./Breakpoints";

// The overlap of the offset items and the current item. Note, this is
// applied first, so it'll shift them scale down, so for small enough
// values the entire offset item may be visible.
const OFFSET_ITEM_OVERLAP = 0.7;
// The amount by which offset items will scale in layered view.
const OFFSET_ITEM_SCALE = 0.75;

const CarouselContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: ${gridSquares(1)};
`;

const CarouselTopRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
`;

const ItemContainer = styled.div<{
    display: "flat" | "layered";
    showArrows: boolean;
}>`
    display: grid;
    position: relative;
    width: 100%;

    ${(p) => {
        switch (p.display) {
            case "flat":
                return `
                    .carousel-item-current {
                        z-index: 1;
                        opacity: 1;
                    }
                    .carousel-item-previous,
                    .carousel-item-next,
                    .carousel-item-hidden {
                        z-index: 0;
                        opacity: 0;
                    }
                `;
            case "layered":
                return `
                    .carousel-item {
                        left: 50%;
                        width: ${
                            p.showArrows
                                ? // Just trust me this is correct. Should keep the edges of the items either side right at the edge of the container.
                                  `calc(100% / (${OFFSET_ITEM_SCALE} + 2 * (1 - ${OFFSET_ITEM_OVERLAP})))`
                                : "100%"
                        };
                    }

                    .carousel-item-current {
                        z-index: 1;
                        opacity: 1;
                        translate: -50%;
                        scale: 1;
                    }
                    .carousel-item-previous {
                        z-index: 0;
                        opacity: 0.5;
                        translate: calc(-50% - (100% * ${
                            1 - OFFSET_ITEM_OVERLAP
                        }));
                        scale: ${OFFSET_ITEM_SCALE};
                    }
                    .carousel-item-next {
                        z-index: 0;
                        opacity: 0.5;
                        translate: calc(-50% + (100% * ${
                            1 - OFFSET_ITEM_OVERLAP
                        }));
                        scale: ${OFFSET_ITEM_SCALE};
                    }
                    .carousel-item-hidden {
                        z-index: 0;
                        opacity: 0;
                        translate: 0%;
                        scale: 0;
                    }
                `;
            default:
                return "";
        }
    }}
`;

const ItemWrapper = styled.div`
    position: relative;
    grid-area: 1 / 1 / 2 / 2;
    transition: translate 700ms ease-in-out, scale 700ms ease-in-out,
        opacity 700ms ease-in-out, z-index 700ms ease-in-out;
`;

const CarouselSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
`;

const ItemSelectorItemWrapper = styled.div<{ showing: boolean }>`
    background: ${(p) => p.theme.color.background.dim};
    width: ${(p) => (p.showing ? "40px" : "16px")};
    height: 16px;
    border-radius: 100vmax;
    cursor: pointer;
    overflow: hidden;
    transition: width 700ms;
`;

const timer = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const ItemSelector = styled.div<{ isTimer: boolean; timeout: number }>`
    height: 100%;
    background: ${(p) => p.theme.color.primary.main};
    border-radius: 100vmax;

    ${(p) => {
        if (!p.isTimer)
            return css`
                width: 100%;
            `;

        return css`
            animation: ${p.timeout}ms ${timer} linear;
        `;
    }}
`;

const useInterval = (callback: () => void, timeout: number) => {
    const id = useRef(null);

    const tick = useCallback(() => callback(), []);

    useEffect(() => {
        id.current = setInterval(tick, timeout);
        return () => clearInterval(id.current);
    }, []);

    return {
        reset: () => {
            clearInterval(id.current);
            id.current = setInterval(tick, timeout);
        },
    };
};

interface PropTypes {
    display: "flat" | "layered";
    showArrows: boolean;
    timeout: number;
    showTimer: boolean;
    children: React.ReactElement[];
}

export const Carousel = ({
    display,
    showArrows,
    timeout,
    showTimer,
    children,
}: PropTypes) => {
    const [currentItem, setCurrentItem] = useState(0);
    const childrenCount = React.Children.count(children);

    // Calculate the indices for the left, right, and current items. It's cleaner here than in CSS.
    const { leftIndex, rightIndex, currentIndex } = useMemo(() => {
        return {
            currentIndex: currentItem,
            leftIndex: currentItem === 0 ? childrenCount - 1 : currentItem - 1,
            rightIndex: currentItem === childrenCount - 1 ? 0 : currentItem + 1,
        };
    }, [currentItem]);

    const increment = () => {
        setCurrentItem((current) => {
            if (current === childrenCount - 1) return 0;
            return current + 1;
        });
    };

    const decrement = () => {
        setCurrentItem((current) => {
            if (current === 0) return childrenCount - 1;
            return current - 1;
        });
    };

    const { reset } = useInterval(increment, timeout);

    // If no children have been passed in, return null
    if (childrenCount === 0) return null;
    return (
        <CarouselContainer>
            <CarouselTopRowContainer>
                {showArrows && childrenCount > 1 && (
                    <ShowWhenGreaterThan screenSize="sm">
                        <Arrow
                            direction="left"
                            color={theme.color.divider.main}
                            hoverColor={theme.color.background.subtleText}
                            size={gridSquares(2)}
                            onClick={() => {
                                reset();
                                decrement();
                            }}
                        />
                    </ShowWhenGreaterThan>
                )}
                <ItemContainer display={display} showArrows={showArrows}>
                    {React.Children.map(
                        children,
                        (child: React.ReactElement, index: number) => (
                            <ItemWrapper
                                key={index}
                                className={`carousel-item ${
                                    currentIndex === index
                                        ? "carousel-item-current"
                                        : rightIndex === index
                                        ? "carousel-item-next"
                                        : leftIndex === index
                                        ? "carousel-item-previous"
                                        : "carousel-item-hidden"
                                }`}
                                onClick={(e) => {
                                    // Prevent non-focused images from opening their links. Only relevant for layered carousels.
                                    if (index !== currentIndex) {
                                        e.preventDefault();
                                    }

                                    if (index !== currentIndex)
                                        setCurrentItem(index);

                                    reset();
                                }}
                            >
                                {child}
                            </ItemWrapper>
                        )
                    )}
                </ItemContainer>
                {showArrows && childrenCount > 1 && (
                    <ShowWhenGreaterThan screenSize="sm">
                        <Arrow
                            direction="right"
                            color={theme.color.divider.main}
                            hoverColor={theme.color.background.subtleText}
                            size={gridSquares(2)}
                            onClick={() => {
                                reset();
                                increment();
                            }}
                        />
                    </ShowWhenGreaterThan>
                )}
            </CarouselTopRowContainer>
            {childrenCount > 1 && (
                <CarouselSelectorContainer>
                    {React.Children.map(
                        children,
                        (_: React.ReactElement, index: number) => (
                            <ItemSelectorItemWrapper
                                showing={index === currentItem && showTimer}
                                onClick={() => {
                                    reset();
                                    setCurrentItem(index);
                                }}
                            >
                                {index === currentItem &&
                                    (showTimer ? (
                                        <ItemSelector
                                            isTimer={true}
                                            timeout={timeout}
                                        />
                                    ) : (
                                        <ItemSelector
                                            isTimer={false}
                                            timeout={timeout}
                                        />
                                    ))}
                            </ItemSelectorItemWrapper>
                        )
                    )}
                </CarouselSelectorContainer>
            )}
        </CarouselContainer>
    );
};
