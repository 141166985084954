import styled from "styled-components";

export const ArticleListLayout = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    > :not(:first-child) {
        margin-top: ${(p) => p.theme.grid.gutters.m};
    }
`;
