import React from "react";
import styled from "styled-components";
import { HeadingLink } from "../Articles/ArticleTiles";
import { hoverStyle, ReadMore, Topic } from "../Articles/components";
import { Carousel } from "../Carousel";
import { Heading } from "../Typography/Heading";

const CAROUSEL_TIMEOUT = 10000;

const FeaturedArticlesWrapper = styled.div`
    margin-bottom: 60px;
`;

const FeaturedArticleItemWrapper = styled.div`
    display: grid;
    position: relative;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    grid-template-areas: "image" "content";
    column-gap: 60px;
    border-radius: ${(p) => p.theme.borderRadius.l};

    ${(p) => p.theme.media("xl")} {
        display: grid;
        // This calculation accounts for the extra gap in the articles when they're three wide
        // allowing them to line up nicely.
        grid-template-columns: calc(33.3333% - 40px) calc(66.6667% - 20px);
        grid-template-areas: "content image";
        align-items: center;
    }

    ${hoverStyle({ scaleImage: true, offsetOutline: true })}

    ${Topic} {
        position: absolute;
        top: 16px;
        right: 16px;
    }
`;

const TextWrapper = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    grid-area: content;
`;

const ArticleHeading = styled(Heading)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    ${(p) => p.theme.media("md")} {
        -webkit-line-clamp: 2;
    }

    ${(p) => p.theme.media("xl")} {
        -webkit-line-clamp: 4;
    }
`;

const Description = styled(Heading)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    ${(p) => p.theme.media("md")} {
        -webkit-line-clamp: 3;
    }

    ${(p) => p.theme.media("xl")} {
        -webkit-line-clamp: 5;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    display: grid;
    grid-area: image;
    aspect-ratio: 5 / 4;

    ${(p) => p.theme.media("md")} {
        aspect-ratio: 16 / 9;
    }

    img {
        border-radius: ${(p) => p.theme.borderRadius.l};
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    transition: ${(p) => p.theme.transition("transform")};
`;

export const BlogListFeaturedArticles = ({ articles }) => {
    return (
        <FeaturedArticlesWrapper>
            <Carousel
                display="flat"
                showArrows={false}
                timeout={CAROUSEL_TIMEOUT}
                showTimer={true}
            >
                {articles.map((article) => (
                    <>
                        <FeaturedArticleItemWrapper>
                            <TextWrapper>
                                <HeadingLink
                                    href={`/blog/${article.slug.current}`}
                                >
                                    <ArticleHeading level="h2" levelStyle="h2">
                                        {article.title}
                                    </ArticleHeading>
                                </HeadingLink>
                                <Description forwardedAs="span" levelStyle="h5">
                                    {article.description}
                                </Description>
                                <ReadMore />
                            </TextWrapper>
                            <ImageWrapper className="featured-image">
                                <img
                                    src={
                                        article.featuredImage.img.asset
                                            .gatsbyImageData.images.fallback.src
                                    }
                                    alt={article.featuredImage.alt}
                                />
                            </ImageWrapper>
                            <Topic>{article.category.name}</Topic>
                        </FeaturedArticleItemWrapper>
                    </>
                ))}
            </Carousel>
        </FeaturedArticlesWrapper>
    );
};
