import { Link } from "gatsby";
import { gridSquares } from "../styles/grid";
import { Icon } from "./Icon";
import React from "react";
import styled, { css } from "styled-components";
import { IconType } from "../types";
import useMedia from "../util/useMedia";
import Button from "./Button";

const NumberRow = styled.li`
    display: flex;
`;

const PaginationButton = styled(Button)<{
    isSelected?: boolean;
    disabled?: boolean;
}>`
    position: relative;
    font-size: 20px;
    width: 1em;
    height: 1em;
    padding: 1em;
    background-color: transparent;
    color: ${(p) => p.theme.color.background.contrastText};
    border: none;
    border-radius: 12px;
    transition: none;

    > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    :hover {
        background-color: ${(p) => p.theme.color.secondaryOne.main};
        color: ${(p) => p.theme.color.secondaryOne.contrastText};
        border: none;

        svg {
            fill: ${(p) => p.theme.color.secondaryOne.contrastText};
        }
    }

    ${(p) =>
        p.isSelected &&
        css`
            background-color: ${p.theme.color.secondaryOne.main};
            color: ${p.theme.color.secondaryOne.contrastText};
            pointer-events: none;

            svg {
                fill: ${p.theme.color.secondaryOne.contrastText};
            }
        `}

    ${(p) =>
        p.disabled &&
        css`
            visibility: hidden;
            pointer-events: none;
        `}
`;

const Wrapper = styled.ul`
    width: 100%;
    max-width: ${(p) => p.theme.maxWidth.paragraph};
    display: flex;
    justify-content: center;
    gap: 4px;
`;

interface Props {
    numPages: number;
    currentPage: number;
}

export const Pagination: React.FC<Props> = ({ numPages, currentPage }) => {
    const isScreenWidthAbove400 = useMedia(400);
    const isScreenWidthAbove664 = useMedia(664);

    const getNumCount = () => {
        if (isScreenWidthAbove664) {
            return 10;
        }

        if (isScreenWidthAbove400) {
            return 5;
        }

        return 3;
    };

    const NUM_COUNT = getNumCount();

    const isHidePagination = numPages === 1;

    if (isHidePagination) {
        return null;
    }

    const isShowBackBtn = numPages > NUM_COUNT;
    const isShowNextBtn = isShowBackBtn;

    const lastPageRange = [
        Math.floor((numPages - 1) / NUM_COUNT) * NUM_COUNT + 1,
        Math.floor((numPages - 1) / NUM_COUNT) * NUM_COUNT + NUM_COUNT,
    ];

    const isDisableBackBtn = currentPage < 11;
    const isDisableNextBtn =
        currentPage >= lastPageRange[0] && currentPage <= lastPageRange[1];

    const firstNumber =
        Math.floor((currentPage - 1) / NUM_COUNT) * NUM_COUNT + 1;

    const prevPathNum = Math.floor((currentPage - 1) / NUM_COUNT) * NUM_COUNT;
    const nextPathNum = Math.ceil(currentPage / NUM_COUNT) * NUM_COUNT + 1;

    return (
        <Wrapper>
            {isShowBackBtn && (
                <PaginationButton
                    href={`/blog/page/${prevPathNum}`}
                    disabled={isDisableBackBtn}
                >
                    <Icon type={IconType.leftArrow} />
                </PaginationButton>
            )}

            {Array.from({ length: NUM_COUNT })
                .map((_, i) => firstNumber + i)
                .filter((itemNumber) => itemNumber <= numPages)
                .map((pageNumber) => (
                    <NumberRow key={pageNumber}>
                        <PaginationButton
                            isSelected={currentPage === pageNumber}
                            href={
                                pageNumber === 1
                                    ? `/blog/`
                                    : `/blog/page/` + pageNumber
                            }
                        >
                            <span>{pageNumber}</span>
                        </PaginationButton>
                    </NumberRow>
                ))}

            {isShowNextBtn && (
                <PaginationButton
                    href={`/blog/page/${nextPathNum}`}
                    disabled={isDisableNextBtn}
                >
                    <Icon type={IconType.rightArrow} />
                </PaginationButton>
            )}
        </Wrapper>
    );
};
