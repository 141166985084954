import styled from "styled-components";

export const SEOOnly = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    pointer-events: none;
    opacity: 0;
`;
